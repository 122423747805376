import React from 'react'
import { Link } from 'react-router-dom';

import './Home.css';
import { FaPaperPlane } from "react-icons/fa6";
import logo1 from "../assets/icons8-protect-50.png"
import logo2 from "../assets/icons8-money-bag-48.png"
import logo3 from "../assets/icons8-fast-50.png"
import about from "../assets/about.jpg"
import choose from "../assets/whyme.jpg"
import save from "../assets/save (1).png"
import anytime from "../assets/anytime.png"
import fast from "../assets/fast2.png"
import coma from "../assets/coma.png"
import { FaChevronCircleRight } from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';


// import required modules
import { Navigation } from 'swiper/modules';



export default function Home() {
    return (
        <>
            <div className="HomeContent">
                <h1>Welcome to Unityhealthsystem</h1>
                <h5>REVENUE CYCLE MANAGEMENT</h5>
            </div>
            <div className="Home">
                <div className="featuresHomePage">
                    <div className="HomeCard">
                        <div className="imgcard"></div>
                        <div className="imgLogo">
                            <img src={logo1} alt="" />
                        </div>
                        <h1>Legally maximize revenues</h1>
                        <h4> Unlock the full potential of your healthcare organization's revenue streams with Unityhealthsystem.</h4>

                    </div>
                    <div className="HomeCard">
                        <div className="imgcard"></div>
                        <div className="imgLogo">
                            <img src={logo2} alt="" />
                        </div>
                        <h1>Improve efficiencies and utilize economy of scale</h1>
                        <h4>Drive efficiency and harness the power of economy of scale with Unityhealthsystem.</h4>
                    </div>
                    <div className="HomeCard">
                        <div className="imgcard"></div>
                        <div className="imgLogo">
                            <img src={logo3} alt="" />
                        </div>
                        <h1>Optimize customer satisfaction, patient, provider and staff</h1>
                        <h4>Elevate customer satisfaction across all fronts patients, providers, and staff  with us</h4>
                    </div>
                </div>
            </div>

            <div className="aboutCompany">
                <div className="aboutImgSection">
                    <div className="aboutImg">
                        <img src={about} alt="" />
                    </div>
                    <div className="cardabout">
                        <h1>25<span>+</span></h1>
                        <h3>YEARS WORKING EXPERIENCE</h3>
                    </div>

                </div>
                <div className="aboutContentSection">
                    <span>ABOUT OUR COMPANY</span>
                    <h1>Innovative Solutions for Revenue Optimization in Healthcare</h1>
                    <h5>Unityhealthsystem is an International “REVENUE CYCLE MANAGEMENT’ Company offering solutions that optimizes processes impacting income and costs. Our entire business focus is providing services to the Healthcare Industry. Our expertise in practice management allows us to deploy “best practice” guidelines while incorporating our domain knowledge to complement our clients ‘requirements.</h5>
                    <div className="aboutContentSection2">
                        <div className="keyAbout">
                            <h5><FaChevronCircleRight style={{ scale: "1.4" }} /> Reduce operational costs</h5>
                            <h5><FaChevronCircleRight style={{ scale: "1.4" }} /> Implement Process Improvement through Analytics</h5>
                            <h5><FaChevronCircleRight style={{ scale: "1.4" }} /> Expand knowledge through communication</h5>
                            <Link className='Link' style={{ color: "white", zIndex: "2" }} to="/Contact">
                                <div className='getIntouchAbout'>
                                    <p className='contantAbout'>Contact<FaPaperPlane /></p>
                                </div>
                            </Link>

                        </div>
                        <div className="contentCard">
                            <div className="rate">
                                <h1>100%</h1>
                                <h5>Success Rate</h5>
                            </div>
                            <div className="lineabout"></div>
                            <div className="client">
                                <h1>5482+</h1>
                                <h5>Satisfied Clients</h5>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="whyYouChooseUs">
                <div className="whyYouChooseImgSection">
                    <div id="triangle-bottomright"></div>
                    <div className="WhyChooseImg">
                        <img src={choose} alt="" />
                    </div>
                    <div id="triangle-topleft"></div>
                </div>
                <div className="whyYouChooseContentSetion">
                    <span>WHY YOU CHOOSE US</span>
                    <h1>Why You Should Choose Unityhealthsystem</h1>
                    <div className="whyYouchooseCards">
                        <div className="whyCards">
                            <div className="cardWhyImg">
                                <img src={save} alt="" />
                            </div>
                            <div className='whyContent'>
                                <h4>Comprehensive Expertise</h4>
                                <h5> Unityhealthsystem specializes solely in healthcare revenue cycle management, guaranteeing an unmatched grasp of the industry's.</h5>
                            </div>

                        </div>
                        <div className="whyCards">
                            <div className="cardWhyImg">
                                <img src={anytime} alt="" />
                            </div>
                            <div className='whyContent'>
                                <h4>Holistic Solutions</h4>
                                <h5>Unityhealthsystem delivers comprehensive solutions that go beyond revenue maximization.</h5>
                            </div>
                        </div>
                        <div className="whyCards">
                            <div className="cardWhyImg">
                                <img src={fast} alt="" />
                            </div>
                            <div className='whyContent'>
                                <h4>Proven Track Record</h4>
                                <h5>Unityhealthsystem has a proven track record of success in helping healthcare organizations achieve their financial goals</h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="reviews">
                <h1>Reviwes</h1>
                <div className="reviewCard">



                    <Swiper navigation={true} modules={[Navigation]} loop={true} className="mySwiper">
                        <SwiperSlide>
                            <div className="reviewLeft">
                                <div className="cooomaReview">
                                    <img src={coma} alt="" />
                                </div>
                                <div className="contentreview">
                                    <div className='pTag'>If you ask me to recommend one Coding company that gives you a transparent working system and that is equipped with cohesive and outstandingly efficient team members, I would undoubtedly say Unityhealthsystem.</div>
                                    <h1><FaUserCircle className='userReviewIcon' />Annie Joseph  Manager, Medical Coding</h1>
                                </div>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="reviewLeft">
                                <div className="cooomaReview">
                                    <img src={coma} alt="" />
                                </div>
                                <div className="contentreview">
                                    <div className='pTag'>I look forward to coming to work each day. I love the vibrance, energy &amp; enthusiasm of the people here.” Appreciation and recognition backed by a well-structured career path makes Unityhealthsystem the best employer in the domain . Continuous training ensured I was certified by the apex body in the business.</div>
                                    <h1><FaUserCircle className='userReviewIcon' />Himanshu Joshi  Manager, Medical Billing</h1>
                                </div>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="reviewLeft">
                                <div className="cooomaReview">
                                    <img src={coma} alt="" />
                                </div>
                                <div className="contentreview">
                                    <div className='pTag'>I am continually challenged in my role…and am afforded the opportunity to make a difference…. I can’t think of anything more fun than that.”Working at Unityhealthsystem has given a whole new direction to my career.Today I have a focused career growth, great compensation and an international work culture.</div>
                                    <h1><FaUserCircle className='userReviewIcon' />Nikkhil Anand  Manager, Quality</h1>
                                </div>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="reviewLeft">
                                <div className="cooomaReview">
                                    <img src={coma} alt="" />
                                </div>
                                <div className="contentreview">
                                    <div className='pTag'>Great work environment, a performance driven work culture and encouraging &amp; supportive peers keep me motivated to deliver my best at all times . I know that being with an employee-eccentric organization like Unityhealthsystem, I don’t simply have a job, I have a career.</div>
                                    <h1><FaUserCircle className='userReviewIcon' />Vinay Dass  Process Executive</h1>
                                </div>

                            </div>
                        </SwiperSlide>

                    </Swiper>


                </div>
            </div>
        </>
    )

}
