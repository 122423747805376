import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import logo from '../assets/aa.png'
import { FaPaperPlane } from "react-icons/fa6";
import { IoMenu } from "react-icons/io5";
import { IoClose } from "react-icons/io5";


import './Navbar2.css';
import HoverMenu from './HoverMenu';
import HoverMenu2 from './HoverMenu2';


export default function Navbar2() {

    const [navMobileContentVisible, setNavMobileContentVisible] = useState(false);

    const toggleNavMobileContent = () => {
        setNavMobileContentVisible(!navMobileContentVisible);
    };

    const closeNavMobileContent = () => {
        setNavMobileContentVisible(false);
    };


    return (
        <>

            <div className="spanCrossNav2">
                <div id="navMobile" onClick={toggleNavMobileContent}><IoMenu style={{ color: "black", scale: '2' }} /></div>
                <div className="navMobilecontent" style={{ display: navMobileContentVisible ? 'block' : 'none' }}>
                    <div className="navContentLinksMobile">
                        <div id="navMobile" onClick={toggleNavMobileContent}><IoClose style={{ color: "black", scale: '2' }} /></div>
                        <div className="NavroutsMobile">
                            <Link className='Link' to="/" onClick={closeNavMobileContent}> <li className='routesLinks'>Home</li></Link>

                            <li className='routesLinks'> <HoverMenu2 title="Delivery" onClick={toggleNavMobileContent} /></li>

                            <li className='routesLinks'> <HoverMenu title="About" onClick={toggleNavMobileContent} /></li>

                            <Link className='Link' to="/Career" onClick={closeNavMobileContent}> <li className='routesLinks'>Career</li></Link>
                        </div>

                        <Link className='Link' style={{ color: "white", zIndex: "2" }} to="/Contact" onClick={closeNavMobileContent}>
                            <div className='getIntouch'>
                                <p className='contantNav2'>Contact<FaPaperPlane /></p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="nav2">
                <div className="nav2Content">
                    <div className="logoNav2">
                        <img src={logo} alt="" />



                    </div>
                    <div className="navContentLinks">
                        <div className="Navrouts">
                            <Link className='Link' to="/"> <li className='routesLinks'>Home</li></Link>
                            {/* <Link className='Link' to="/Services"> <li>Services</li></Link> */}
                            <li className='routesLinks'> <HoverMenu2 title="Delivery" /></li>
                            <li className='routesLinks'> <HoverMenu title="About" /></li>
                            <Link className='Link' to="/Career"> <li className='routesLinks'>Career</li></Link>
                        </div>

                        <Link className='Link' style={{ color: "white", zIndex: "2" }} to="/Contact">
                            <div className='getIntouch'>
                                <p className='contantNav2'>Contact<FaPaperPlane /></p>
                            </div>
                        </Link>
                    </div>

                </div>
            </div>
        </>
    )
}
