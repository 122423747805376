import React from 'react'
import './Insurance1.css'
import { Link } from 'react-router-dom'

import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";

import tasweer from '../assets/serviceCard.jpg'

function Insurance3() {
    return (
        <>
            <div>
                <div className='insurance1Service'>
                    <h1 className='insuranceName1'>Current Client Specialties</h1>
                </div>

                <div className='insurance1Bottom'>
                    <div className='insurance1Left'>
                        <Link className='Link' to="/Insurance1">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Insurance Verification
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance2">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Transition Methodology
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance3">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Current Client Specialties
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance4">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Insurance Eligibility
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance4">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Benefit Verification
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance5">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                Account Receivables
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <div className='TalkToInsurance'>
                            <div className='InsuranceImage'>
                                <div className='serviceIcon' ><FiPhoneCall style={{
                                    height: '2.5rem', width: '2.5rem', color: 'white'
                                }} /></div>
                                <h1 className='AgentLargeText' style={{ textAlign: "center", color: 'white' }}>Talk to our
                                    <br />
                                    insurance agent</h1>
                                <h4 style={{ textAlign: "center", color: 'white', margin: '2rem 0 0 0' }}>+92 (003) 68-090</h4>
                                <h5 style={{ textAlign: "center", color: 'white', margin: '0' }}>Call to Our Experts</h5>
                            </div>
                        </div>
                    </div>
                    <div className='insurance1Right'>
                        <div className='rightinsuranceImage'>
                            <img className='rightinsuranceImageSize' src={tasweer} alt='...' />
                        </div>
                        <div className='rightinsuranceText' style={{textAlign:'start'}}><h3>We currently provide full service to the following specialties:</h3>
                            Orthopedic Surgery
                            <br/><br/>
                            • Hand Specialist<br/>
                            • Joint Replacement<br/>
                            • Sports Medicine<br/><br/>
                            Bariatric Surgery
                            <br/>
                            General Surgery
                            <br/>
                            Reconstructive and Cosmetic surgery
                            <br/>
                            Medical Weight Loss
                            <br/>
                            Nutritional Counseling
                            <br/>
                            Behavior Counseling
                            <br/>
                            Sleep Medicine
                            <br/>
                            Sleep studies [Sleep Lab as well as Home studies]
                            <br/>
                            Internal Medicine/Family Medicine
                            <br/>
                            Radiology
                            <br/>
                            Cardiology<br/>
                            Pulmonology<br/>
                            Neurology<br/>
                            Ambulatory Surgical Center</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Insurance3
