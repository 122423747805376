import React from 'react'
import "./About.css"


export default function About() {
    return (
        <>
            <div className="AboutBanner">
                <h1>About Us</h1>
            </div>

          
            <div className="contactAbout">
                <div className="aboutTag">
                    <span>About US</span>
                    <p>Overview about the company</p>
                </div>
                <p>Unityhealthsystem provides innovative, effective and high quality Revenue Cycle Management (RCM) Services to Health Care providers in United States. We, at Unityhealthsystem, offer a delivery system of services from our facilities in India and the US that serve as Business Continuity sites for each other. Our services minimze the risks and secure benefits through a structured, proven approach that has been successfully deployed over the years. Unityhealthsystem is one of the pioneers in offshore outsourcing services. Our approach in Medical Coding, Medical Transcription, Medical Billing, Insurance Claim Processing etc. has a successful track record of providing high standards in patient information management services. Our facilities are scalable to serve clients with large sized RCM initiatives.</p>
                <div className="parallerEffect">
                <h1>Unityhealthsystem</h1>
            </div>
                <p>We utilize technology, well qualified and experienced Coders, Billing Executives and Managers in conjunction with high-end software technologies to provide 24 x 7 end to end solutions in all areas of Medicine. Unityhealthsystem assists providers by managing their patient’s information data, business office operations and report generation through a solution-oriented approach, allowing the practice to remain diligent with their business priorities. Our solutions are based on proven industry best practices, and are provided by the best talent available in India, with US on site management and domain knowledge. We, at Unityhealthsystem, focus on delivering measurable business outcomes through programs that are:</p>
                <div className='aboutTextList'>


                    <div className='aboutPart1'>
                        <ul className='ClassUl'>
                            <li className='liClass'>1. Cost effective</li>
                            <li className='liClass'>2. Transparent</li>
                            <li className='liClass'>3. With a foundation of process and domain expertise</li>
                            <li className='liClass'>4. Maintaining the highest quality standards</li>
                            <li className='liClass'>5. Meeting and exceeding Service Level Agreements</li>
                            <li className='liClass'>6. Employing continuous process improvement initiatives</li>
                            <li className='liClass'>7. Utilizing the most current technologies</li>
                        </ul>

                    </div>
                    <div className='aboutPart2'>
                        <h3>The following are the flexible outsourcing models that are designed to meet clients’ financial objectives:</h3>
                        <ul className='ClassUl'>
                            <li className='liClass'>1. Agent-based pricing</li>
                            <li className='liClass'>2. Transaction-based pricing</li>
                            <li className='liClass'>3. Performance (Risk) arrangements</li>
                        </ul>
                    </div>

                </div>


             
            </div>
        </>
    )
}
